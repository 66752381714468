import React from "react";
import {IAddBookingDialogTypes} from "./types";
import {Dialog, DialogTitle} from "@mui/material";
import {InfoDialogContent} from "./InfoDialogContent";
import {BookingDialogContent} from "./BookingDialogContent";
import {Booking} from "../../models/Booking";
import {useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getTitle} from "../../utils/getTitle";

export const AddBookingDialog = (props: IAddBookingDialogTypes): React.ReactElement => {
  const [searchParams] = useSearchParams();
  const {t} = useTranslation();

  const [step, setStep] = React.useState<"info" | "bookingProcess">("info");

  const language = searchParams.get("lang") || "en-US";

  const onCancel = (): void => {
    props.onCancel();

    resetStates()
  }

  const onApply = (booking: Booking): void => {
    if (booking.seats <= props.event.seatsAvailable) {
      props.onApply(booking)

      resetStates()
    } else {
      //show error
    }
  }

  const resetStates = (): void => {
    setStep("info")
  }

  return (
    <Dialog
      PaperProps={{
        sx: {
          minHeight: 650
        }
      }}
      open={props.show}
      onClose={onCancel}
      fullWidth={true}
      maxWidth="md">
      <DialogTitle>{props.event ? getTitle(language, props.event, t) : ""}</DialogTitle>

      {step === "info" && (
        <InfoDialogContent
          onCancel={props.onCancel}
          onStartBookingProcess={() => {
            setStep("bookingProcess");
          }}
          event={props.event}/>
      )}

      {step === "bookingProcess" && (
        <BookingDialogContent
          onCancel={onCancel}
          onApply={onApply}
          loading={props.loading}
          event={props.event}
        />
      )}
    </Dialog>
  );
}