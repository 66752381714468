import {TripEvent} from "../models/TripEvent";
import {TFunction} from "react-i18next";

export const getTitle = (language, event: TripEvent, t: TFunction<"translation", undefined>) => {
  switch (language) {
    case  "da-DK":
      return event && event.title && event.title.length > 0 ? event.title : t("no-title");
    case "de-DE":
      return event && event.title_de && event.title_de.length > 0 ? event.title_de : t("no-title");
    default:
    case "en-US":
      return event && event.title_en && event.title_en.length > 0 ? event.title_en : t("no-title");
  }
}