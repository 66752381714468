import {TripEvent} from "../models/TripEvent";
import {TFunction} from "react-i18next";

export const getDescription = (language, event: TripEvent, t: TFunction<"translation", undefined>) => {
  switch (language) {
    case  "da-DK":
      return event && event.description && event.description.length > 0 ? event.description : t("no-description");
    case "de-DE":
      return event && event.description_de && event.description_de.length > 0 ? event.description_de : t("no-description");
    default:
    case "en-US":
      return event && event.description_en && event.description_en.length > 0 ? event.description_en : t("no-description");
  }
}